import { lazy } from "react"
import AuthorizedRoute from "../../base/components/AuthorizedRoute"
import UnauthorizedRoute from "../../base/components/UnauthorizedRoute"
import { Navigate } from "react-router-dom"
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"


const LoginPage = lazy(() => import('../pages/LoginPage'))
const Dashboard = lazy(() => import('../pages/DashboardPage'))
const UserProfile = lazy(() => import('../pages/UserProfilePage'))
const ChangePassword = lazy(() => import('../pages/ChangePasswordPage'))
const Users = lazy(() => import('../pages/UsersPage'))
const Invitations = lazy(() => import('../pages/InvitationsPage'))
const Support = lazy(() => import('../pages/SupportPage'))
const Help = lazy(() => import('../pages/HelpPage'))
const About = lazy(() => import('../pages/AboutPage'))
const User = lazy(() => import('../pages/UserPage'))
const Invitation = lazy(() => import('../pages/InvitationPage'))
const InvitedUser = lazy(() => import('../pages/InvitedUserPage'))
const ForgotPassword = lazy(() => import('../pages/ForgotPasswordPage'))
const RegisterUser = lazy(() => import('../pages/RegisterUserPage'))
const Analysis = lazy(() => import('../pages/AnalysisDetailPage'))
const OrganizationsList = lazy(() => import('../pages/OrganizationsPage'))
const OrganizationPage = lazy(() => import('../pages/OrganizationPage'))
const DepartmentPage = lazy(() => import('../pages/DepartmentPage'))
const ActionLogs = lazy(() => import('../pages/ActionLogsPage'))
const ActionLog = lazy(() => import('../pages/ActionLogPage'))
const AgreementManual = lazy(() => import("../components/AgreementManual"))
const PriceListsList = lazy(() => import("../pages/PriceListsPage"))
const PriceList = lazy(() => import("../pages/PriceListPage"))
const PriceListVersion = lazy(() => import("../pages/PriceListVersionPage"))
const ContractsList = lazy(() => import("../pages/ContractsPage"))
const Contract = lazy(() => import("../pages/ContractPage"))
const OrganizationPriceListPage = lazy(() => import("../pages/OrganizationPriceListPage"))
const ActualOrganizationPriceListPage = lazy(() => import("../pages/ActualOrganizationPriceListPage"))
const ActualOrganizationContractPage = lazy(() => import("../pages/ActualOrganizationContractPage"))
const TransactionLogs = lazy(() => import('../pages/TransactionLogsPage'))
const TransactionLog = lazy(() => import('../pages/TransactionLogPage'))
const CreditDepositPage = lazy(() => import("../pages/CreditDepositPage"))
const OrganizationContractPage = lazy(() => import("../pages/OrganizationContractPage"))
const MonthlyReports = lazy(() => import('../pages/MonthlyReportsPage'))
const AdHocReport = lazy(() => import('../pages/AdHocReportPage'))


export interface IRoute {
    path: string,
    exact?: boolean,
    element: ReactJSXElement
}

const routes: IRoute[] = [
    {
        path: '/',
        exact: true,
        element: <Navigate to="/dashboard" replace={true} />
    },
    {
        path: '/signin',
        exact: true,
        element: (
            <UnauthorizedRoute redirectTo="/dashboard">
                <LoginPage />
            </UnauthorizedRoute>
        ),
    },
    {
        path: '/agreements',
        exact: true,
        element: (
            <AuthorizedRoute>
                <AgreementManual />
            </AuthorizedRoute>
        )
    },
    {
        path: '/user_profile',
        exact: true,
        element: (
            <AuthorizedRoute>
                <UserProfile/>
            </AuthorizedRoute>
        )
    },
    {
        path: '/registration',
        exact: true,
        element: (
            <RegisterUser/>
        )
    },
    {
        path: '/change_password',
        exact: true,
        element: (
            <AuthorizedRoute>
                <ChangePassword/>
            </AuthorizedRoute>
        )
    },
    {
        path: '/forgot_password',
        exact: true,
        element: (
            <UnauthorizedRoute redirectTo="/dashboard">
                <ForgotPassword />
            </UnauthorizedRoute>
        )
    },
    {
        path: '/reset_password/:password_token/:auth_token',
        exact: true,
        element: (
            <UnauthorizedRoute redirectTo="/dashboard">
                <ForgotPassword withToken={true} />
            </UnauthorizedRoute>
        )
    },
    {
        path: '/dashboard',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Dashboard />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/analysis/create',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Analysis createPage={true}/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/analysis/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Analysis createPage={false}/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-users',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Users />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-users/create',
        exact: true,
        element: (
            <AuthorizedRoute>
                <User createPage={true}/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-users/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <User createPage={false}/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/invited_user/:key',
        exact: true,
        element: (
            <UnauthorizedRoute redirectTo="/dashboard">
                <InvitedUser />
            </UnauthorizedRoute>
        ),
    },
    {
        path: '/admin-invitations',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Invitations />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-invitations/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Invitation createPage={false}/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-invitations/create',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Invitation createPage={true}/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-action-logs',
        exact: true,
        element: (
            <AuthorizedRoute>
                <ActionLogs />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-action-logs/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <ActionLog />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/support',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Support />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/help',
        exact: true,
        element: (
            <AuthorizedRoute>
                <Help />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/about',
        exact: true,
        element: (
                <About />
        ),
    },
    {
        path: '/admin-organizations',
        exact: true,
        element: (
            <AuthorizedRoute>
                <OrganizationsList />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-organizations/create',
        exact: true,
        element: (
            <AuthorizedRoute>
                <OrganizationPage createPage={true}/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-organizations/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <OrganizationPage createPage={false}/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-departments/create/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <DepartmentPage createPage={true}/>
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-departments/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <DepartmentPage createPage={false}/>
            </AuthorizedRoute>
        ),
    },
    {
        path: "/admin-pricelists/",
        exact: true,
        element: (
            <AuthorizedRoute>
                <PriceListsList/>
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-pricelists/:uid",
        exact: true,
        element: (
            <AuthorizedRoute>
                <PriceList createPage={false}/>
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-pricelists/create",
        exact: true,
        element: (
            <AuthorizedRoute>
                <PriceList createPage={true}/>
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-pricelists/:uid/versions/:vid",
        exact: true,
        element: (
            <AuthorizedRoute>
                <PriceListVersion/>
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-pricelists/:uid/versions/:vid/edit_future_version",
        exact: true,
        element: (
            <AuthorizedRoute>
                <PriceListVersion editable={true}/>
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-contracts/",
        exact: true,
        element: (
            <AuthorizedRoute>
                <ContractsList/>
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-contracts/:uid",
        exact: true,
        element: (
            <AuthorizedRoute>
                <Contract createPage={false}/>
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-contracts/create",
        exact: true,
        element: (
            <AuthorizedRoute>
                <Contract createPage={true}/>
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-organizations/:uid/pricelists",
        exact: true,
        element: (
            <AuthorizedRoute>
                <OrganizationPriceListPage />
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-organizations/:uid/contracts",
        exact: true,
        element: (
            <AuthorizedRoute>
                <OrganizationContractPage />
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-organizations/:uid/actual_price_list/:vid",
        exact: true,
        element: (
            <AuthorizedRoute>
                <ActualOrganizationPriceListPage />
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-organizations/:uid/actual_contract/:vid",
        exact: true,
        element: (
            <AuthorizedRoute>
                <ActualOrganizationContractPage />
            </AuthorizedRoute>
        )
    },
    {
        path: "/admin-organizations/:uid/credit_deposit",
        exact: true,
        element: (
            <AuthorizedRoute>
                <CreditDepositPage />
            </AuthorizedRoute>
        )
    },
    {
        path: '/admin-transaction-logs',
        exact: true,
        element: (
            <AuthorizedRoute>
                <TransactionLogs />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-transaction-logs/:uid',
        exact: true,
        element: (
            <AuthorizedRoute>
                <TransactionLog />
            </AuthorizedRoute>
        ),
    },
    {
        path: '/admin-monthly-reports/',
        exact: true,
        element: (
            <AuthorizedRoute>
                <MonthlyReports />
            </AuthorizedRoute>
        ),
    },
    {
        path: "/admin-adhoc-report/",
        exact: true,
        element: (
            <AuthorizedRoute>
                <AdHocReport />
            </AuthorizedRoute>
        )
    }
]
export default routes