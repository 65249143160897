import ReactDOM from 'react-dom'
import App from './src/base/containers/App'
import config from './src/app/config'


ReactDOM.render(
    <App config={config} />,
    document.querySelector('#app')
)

document.onkeydown = (e: KeyboardEvent) => {
    const canvas = document.querySelector("[data-testid*='pdf_viewer_document_page']")

    if (e.ctrlKey && (e.key == "p") && canvas) {
        e.stopPropagation()
        e.preventDefault()
    }
}