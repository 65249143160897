import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"
import { lazy } from "react"

const PageNotFound = lazy(() => import('../../base/components/PageNotFound'))
import AuthorizedRoute from "../../base/components/AuthorizedRoute"


const getDefaultRoutes = (appConfig: any): { path: string, element: ReactJSXElement }[] => {
    return [
        {
            path: '*',

            element: (
                <AuthorizedRoute>
                    <PageNotFound/>
                </AuthorizedRoute>
            ),
        },
    ]
}

export {getDefaultRoutes}